<template>
  <section>
    <div class="py-10 bg-gray-50 radius-for-skewed">
      <div class="container mx-auto px-4">
        <div class="mb-8 md:mb-16 max-w-xl mx-auto text-center">
          <form class="flex justify-center mb-2">
            <input v-if="applications && applications.length > 5" v-model="searchTerm"
              class="w-2/3 p-4 text-xs font-heading bg-white focus:border-gray-500 focus:outline-none rounded-l"
              placeholder="Meine Anwendungen filtern...">
            <button v-if="applications && applications.length > 5" class="pr-4 rounded-r-lg bg-white text-green-600">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
            <button v-if="connectionType == 'remoteapp'"
              class="ml-4 w-full py-2 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50" @click="openPluginUrl()">⬇️ RemoteApp
              Plugin herunterladen ⬇️</button>
          </form>
          <div class="mb-6">
            <label class="mr-2">
              <input type="radio" name="connectionType" value="remoteapp" v-model="connectionType">
              <span class="ml-1">RemoteApp (Für Windows Empfohlen)</span>
            </label>
            <label class="mr-2">
              <input type="radio" name="connectionType" value="webbrowser" v-model="connectionType">
              <span class="ml-1">Webbrowser</span>
            </label>
            <label>
              <input type="radio" name="connectionType" value="rdpfile" v-model="connectionType">
              <span class="ml-1">RDP Datei</span>
            </label>
          </div>
        </div>

        <div
          class="flex flex-wrap mb-8 -mx-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 place-items-center">
          <div class="w-full mb-8 px-4" v-for="application in filteredApplications" v-bind:key="application">
            <div class="relative mx-auto h-64 w-full rounded-lg">
              <img v-if="application.iconUrl" class="relative h-full w-full rounded-lg object-cover"
                :src="application.iconUrl" alt="">
              <img v-else class="relative h-full w-full rounded-lg object-cover"
                src="https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                alt="">
            </div>
            <div class="p-6">
              <h3 class="mb-4 text-2xl font-bold font-heading">{{ application.name }}</h3>
              <button :disabled="application.buttonDisabled" class="flex text-green-600 hover:text-green-700 font-bold"
                @click="createApplicationContainer(application._id)">
                <svg v-bind:class="application.launchButtonAnimated" class="mr-3 w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                    clip-rule="evenodd"></path>
                </svg>
                <span>Starten</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div>
  </div>
  <vue-final-modal v-model="showPluginNotFoundModal" classes="modal-container" content-class="modal-content" :esc-to-close="true" :lock-scroll="false">
  <div class="py-5 bg-gray-50 radius-for-skewed">
    <div class="container mx-auto px-4">
      <div class="mx-auto max-w-md text-center">
        <h2 class="text-4xl lg:text-5xl font-bold font-heading">RemoteApp Plugin nicht gefunden</h2>
        <p class="mt-10">Es sieht so aus, als ob unser RemoteApp Plugin noch nicht installiert ist. Klicke auf den folgenden Knopf um es herunterzuladen</p>
        <br>
                    <button v-if="connectionType == 'remoteapp' || connectionType == 'rdpfile'"
              class="w-full py-2 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50" @click="openPluginUrl()">⬇️ RemoteApp
              Plugin herunterladen ⬇️</button>
      </div>
    </div>
  </div>
</vue-final-modal>
</template>

<script>
import axios from 'axios';
import downloadjs from 'downloadjs';
import copy from 'copy-to-clipboard';
import Fuse from 'fuse.js'
import customProtocolCheck from "custom-protocol-check";

export default {
  name: 'Applications',
  data() {
    return {
      showPluginNotFoundModal: false,
      applications: null,
      filteredApplications: this.applications,
      showModal: false,
      lastLaunchedApplication: null,
      searchTerm: null,
      connectionType: 'remoteapp',
      showRemoteAppSuccessModal: true,
    }
  },
  mounted() {
    // 60e83c60f24b4f8c861570be
    console.log(`${process.env.VUE_APP_BACKEND_URL}/users/${this.$store.getters.user['userId']}/applications`)
    axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/users/${this.$store.getters.user['userId']}/applications`)
      .then(response => {
        this.applications = response.data
        this.filteredApplications = response.data
      })

    console.log(this.$store.getters.user)
  },
  methods: {
    getGuacamoleUrl() {
      // this.lastLaunchedApplication.guacamoleUrl
      const guacamoleUrl = new URL(this.lastLaunchedApplication.guacamoleUrl)
      const newGuacamoleUrl = `https://c3b48bbe.rdp.clubinone.de/${guacamoleUrl.port}${guacamoleUrl.pathname}${guacamoleUrl.search}`
      console.log(guacamoleUrl)
      return newGuacamoleUrl

    },
    openPluginUrl() {
      window.open('https://win-api.fra1.digitaloceanspaces.com/DockerWinPlugin.exe')
    },
    async createApplicationContainer(applicationId) {
      // launchButtonAnimated
      let application = this.filteredApplications.find(element => element._id == applicationId);
      application.launchButtonAnimated = 'animate-spin'
      application.buttonDisabled = true

      const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/applications/${applicationId}/containers`)

      this.lastLaunchedApplication = response.data
      console.log(response.data)

      //await new Promise(resolve => setTimeout(resolve, 2500));

      this.openApplication()



      //copy(response.data.rdpPassword)
      //downloadjs(atob(response.data.rdpFileBase64), 'test.rdp')

      //this.showModal = true

      application.launchButtonAnimated = undefined
      application.buttonDisabled = false
    },
    async copyLastPasswordToClipboard() {
      copy(this.lastLaunchedApplication.rdpPassword)
    },
    async downloadLastRdpFile() {
      downloadjs(atob(this.lastLaunchedApplication.rdpFileBase64), `${this.lastLaunchedApplication.applicationName}.rdp`)
    },
    async openApplication() {
      if (this.connectionType === 'remoteapp') {
        //window.open(`openrdp://${response.data.rdpFileBase64}/${response.data.rdpPassword}`, "_self")

        customProtocolCheck(
          `openrdp://${this.lastLaunchedApplication.rdpFileBase64}/${this.lastLaunchedApplication.rdpPassword}`,
          () => {
            this.showPluginNotFoundModal = true
            console.log("Custom protocol not found.");
          },
          () => {
            this.showPluginNotFoundModal = false
            console.log("Custom protocol found and opened the file successfully.");
          }, 5000
        );
      } else if (this.connectionType === 'rdpfile') {
        console.log('asdf')
        copy(this.lastLaunchedApplication.rdpPassword)
        downloadjs(atob(this.lastLaunchedApplication.rdpFileBase64), `${this.lastLaunchedApplication.applicationName}.rdp`)
      } else {
        console.log('asdfhasdfhjasdhfkashjdkfhjads')
        window.open(this.getGuacamoleUrl())
      }
    }
  },
  watch: {
    searchTerm: async function (val) {
      if (val === '') {
        return this.filteredApplications = this.applications
      }

      const options = {
        includeScore: false,
        findAllMatches: true,
        // Search in `author` and in `tags` array
        keys: ['name']
      }

      const fuse = new Fuse(this.applications, options)

      const result = fuse.search(val)

      let cleanedResult = []

      result.forEach(i => {
        cleanedResult.push(i.item)
      })

      return this.filteredApplications = cleanedResult
    }
  }
}
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>